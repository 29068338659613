import { routing as tradeOfferRouting } from '../offer/trade/routes';
import { routing as corpOfferRouting } from '../offer/corp/routes';
import { routing as bookingOfferRouting } from '../offer/booking/routes';
import { ECatalogTab } from '../catalog/types';

import { routing as productOfferRouting } from '../offer/product/routes';

export enum EGlobalUrlParam {
  Search = 'search',
}

export const offerSectionToTabMap = [
  {
    tab: ECatalogTab.TradeOffers,
    path: tradeOfferRouting.list,
  },
  {
    tab: ECatalogTab.CorpOffers,
    path: corpOfferRouting.list,
  },
  {
    tab: ECatalogTab.BookingOffers,
    path: bookingOfferRouting.list,
  },
  {
    tab: ECatalogTab.ProductOffers,
    path: productOfferRouting.root,
  },
  {
    tab: ECatalogTab.AspOffers,
    path: tradeOfferRouting.asp,
  },
];
