import { SvgIconProps } from '@mui/material';
import { ECatalogTab } from '../../../features/catalog/types';
import { ECmsBannerLinkObjectType, ECmsCollectionLinkObjectType, EOfferType } from '../../../../domain/model/enums';
import { SvgIconComponent } from '@mui/icons-material';
import { BagIcon, CertificateIcon, CorpOfferIcon, SeeAndSunIcon, TradeOfferIcon } from '../../../media/icons';
import { FC } from 'react';
import { EAppCategories } from '../../../types';

type Types = ECatalogTab | EOfferType | ECmsCollectionLinkObjectType | ECmsBannerLinkObjectType | EAppCategories;

export type PartitionIconProps = SvgIconProps & {
  readonly type: Types;
};

export const getPartitionIcon = (type: Types): Nullable<SvgIconComponent> => {
  switch (type) {
    case EOfferType.Trade:
    case EAppCategories.TradeOffers:
    case ECmsCollectionLinkObjectType.TradeOffer:
    case ECmsBannerLinkObjectType.TradeOffer:
    case ECmsBannerLinkObjectType.TradeOfferCategory:
    case ECatalogTab.TradeOffers:
      return TradeOfferIcon;

    case EOfferType.Corp:
    case EAppCategories.CorpOffers:
    case ECmsCollectionLinkObjectType.CorpOffer:
    case ECmsBannerLinkObjectType.CorpOffer:
    case ECmsBannerLinkObjectType.CorpOfferCategory:
    case ECatalogTab.CorpOffers:
      return CorpOfferIcon;

    case EOfferType.Booking:
    case EAppCategories.BookingOffers:
    case ECatalogTab.BookingOffers:
      return SeeAndSunIcon;

    case EOfferType.Product:
    case EAppCategories.ProductOffers:
    case ECatalogTab.ProductOffers:
      return BagIcon;

    case EAppCategories.AspOffers:
    case ECatalogTab.AspOffers:
      return CertificateIcon;

    case ECmsCollectionLinkObjectType.Partner:
    case ECmsBannerLinkObjectType.Partner:
    case ECmsBannerLinkObjectType.Collection:
    case ECmsBannerLinkObjectType.Link:
      return null;
  }
};

export const PartitionIcon: FC<PartitionIconProps> = ({ type, ...others }) => {
  const Component = getPartitionIcon(type);

  return Component ? <Component {...others} /> : null;
};
