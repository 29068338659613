import { useAuthUser } from 'presentation/features/auth/provider/useAuthUser';
import useCorpOfferDetailsData from 'presentation/features/offer/corp/details/hooks/useCorpOfferDetailsData';
import CorpOfferSlotSidebar from 'presentation/features/offer/corp/details/sidebar';
import CorpOfferSidebarDialogsProvider from 'presentation/features/offer/corp/details/sidebar/dialogProvider/provider';
import CorpOfferSidebarProvider from 'presentation/features/offer/corp/details/sidebar/provider/provider';
import { EPartnerDeskDetailsTab } from 'presentation/features/partnerDesk/details/utils';
import { getPartnerDeskDetailsRoute } from 'presentation/features/partnerDesk/entry';
import { useHistory } from 'react-router';

type CorpOfferDetailSidebarAdapterProps = {
  readonly id: UUID;
};

const CorpOfferDetailSidebarAdapter = ({ id }: CorpOfferDetailSidebarAdapterProps) => {
  const history = useHistory();

  const { user } = useAuthUser();
  const { corpOffer } = useCorpOfferDetailsData(id);

  const onShowAllOffers = () => {
    if (corpOffer) {
      history.push(
        getPartnerDeskDetailsRoute({
          id: corpOffer.partner.id,
          tab: EPartnerDeskDetailsTab.CorpOffers,
        })
      );
    }
  };

  if (!corpOffer) {
    return null;
  }

  return user ? (
    <CorpOfferSidebarProvider
      id={corpOffer.id}
      promotionType={corpOffer.promotionType}
    >
      <CorpOfferSidebarDialogsProvider
        corpOffer={corpOffer}
        onShowAllOffers={onShowAllOffers}
      >
        <CorpOfferSlotSidebar />
      </CorpOfferSidebarDialogsProvider>
    </CorpOfferSidebarProvider>
  ) : null;
};

export default CorpOfferDetailSidebarAdapter;
