import rootRouting from '../../../../routing';
import { GetSearchResultsRouteProps } from '../types';
import { Location } from 'history';
import { SearchResultsLocationState } from '../../search/entry';
import { v4 as uuidv4 } from 'uuid';
import { ESearchUrlParam } from '../../../types';
import { CorpOffersListLocationState } from '../corp/entry';
import { Nullable } from '../../../../domain/model/types';

const root = rootRouting.tradeOffer;
export const routing = {
  list: root,
  asp: `${root}/asp`,
  details: `${root}/:id`,
  search: `${root}/search`,
};

export const getTradeOfferSearchResultsRoute = (
  props: GetSearchResultsRouteProps
): Location<SearchResultsLocationState> => {
  const { guid, query } = props;

  const newGuid = guid ?? uuidv4();

  const searchParams = new URLSearchParams();

  if (query) {
    searchParams.append(ESearchUrlParam.Query, query);
  }

  return {
    pathname: `${routing.search}`,
    search: `?${searchParams.toString()}`,
    state: {
      guid: newGuid,
    },
    hash: '',
  };
};

type GetTradeOffersAspListRouteProps = {
  readonly guid?: Nullable<UUID>;
};

export const getTradeOffersAspListRoute = (
  props?: GetTradeOffersAspListRouteProps
): Location<CorpOffersListLocationState> => {
  const newGuid = props?.guid ?? uuidv4();

  return {
    pathname: routing.asp,
    search: '',
    state: {
      guid: newGuid,
    },
    hash: '',
  };
};
