import { skipToken } from '@reduxjs/toolkit/query';
import { useGetUserBonusesBalanceQuery } from 'data/api/user';
import { User } from 'domain/model/user';
import { getBonusConfig } from 'presentation/utils';
import { FCC, useCallback, useEffect, useState } from 'react';
import useErrorTracking from '../../../../hooks/useErrorTracking';
import CurrentUserBalanceContext, { CurrentUserBalanceContextType } from './context';

type CurrentUserBalanceProviderProps = {
  readonly user: Nullable<User>;
  readonly isCorpUser: boolean;
};

const CurrentUserBalanceProvider: FCC<CurrentUserBalanceProviderProps> = props => {
  const { user, isCorpUser, children } = props;

  const [contextValue, setContextValue] = useState<CurrentUserBalanceContextType>({
    value: null,
    isFetching: false,
    refreshBalance: () => null,
  });

  const { captureException } = useErrorTracking();

  const skipRequest = !isCorpUser || !user?.pernr || !!getBonusConfig()?.unavailableReason;

  const {
    data: bonusesBalance = null,
    isFetching: bonusesBalanceIsFetching,
    error: bonusesFetchError,
    refetch: refreshBonusesBalanceInternal,
    isUninitialized: bonusesBalanceisUninitialized,
  } = useGetUserBonusesBalanceQuery(user ? { userId: user.id } : skipToken, {
    refetchOnMountOrArgChange: true,
    pollingInterval: 0.5 * 60 * 1000,
    skip: skipRequest,
  });

  const refreshBonusesBalance = useCallback(() => {
    if (!bonusesBalanceisUninitialized) {
      refreshBonusesBalanceInternal();
    }
  }, [bonusesBalanceisUninitialized, refreshBonusesBalanceInternal]);

  // ошибка получения баланса юзера
  useEffect(() => {
    if (bonusesFetchError) {
      const errorText = 'Error at request current user bonuses';
      console.error(errorText, bonusesFetchError);
      captureException(errorText);
    }
  }, [bonusesFetchError, captureException]);

  // обновление стейта баланса для оптимальности
  useEffect(() => {
    setContextValue({
      value: bonusesBalance,
      isFetching: bonusesBalanceIsFetching,
      refreshBalance: refreshBonusesBalance,
    });
  }, [bonusesBalance, bonusesBalanceIsFetching, refreshBonusesBalance]);

  return <CurrentUserBalanceContext.Provider value={contextValue}>{children}</CurrentUserBalanceContext.Provider>;
};

export default CurrentUserBalanceProvider;
