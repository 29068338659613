import TradeOfferSlotSidebar from 'presentation/features/offer/trade/details/sidebar';
import TradeOfferSidebarDialogsProvider from 'presentation/features/offer/trade/details/sidebar/dialogProvider/provider';
import { EPartnerDeskDetailsTab } from 'presentation/features/partnerDesk/details/utils';
import { getPartnerDeskDetailsRoute } from 'presentation/features/partnerDesk/entry';
import { useWebAnalytics } from 'presentation/features/webAnalytics';
import { useHistory } from 'react-router';
import useTradeOfferDetailsData from '../hooks/useTradeOfferDetailsData';
import useTradeOfferPartnerDeskData from '../hooks/useTradeOfferPartnerDeskData';
import TradeOfferSidebarProvider from '../sidebar/provider/provider';
import { useAuth } from 'presentation/features/auth/provider/useAuth';

type TradeOfferSidebarAdapterProps = {
  readonly id: UUID;
  readonly guid: UUID;
};

const TradeOfferSidebarAdapter = ({ id, guid }: TradeOfferSidebarAdapterProps) => {
  const history = useHistory();

  const { isAuthenticated } = useAuth();
  const { tradeOffer } = useTradeOfferDetailsData(id);
  const { partnerDesk } = useTradeOfferPartnerDeskData(id);
  const { webAnalytics } = useWebAnalytics();

  const onPartnerButtonClick = () => {
    if (tradeOffer?.partnerId) {
      webAnalytics.openPartnerUrl(tradeOffer?.partnerId);
      webAnalytics.offerJumpToPartnerSite(tradeOffer.id);
    }
  };

  const onShowAllOffers = () => {
    if (partnerDesk) {
      history.push(getPartnerDeskDetailsRoute({ id: partnerDesk.id, tab: EPartnerDeskDetailsTab.TradeOffers }));
    }
  };

  if (!tradeOffer) {
    return null;
  }

  return (
    <TradeOfferSidebarProvider
      id={tradeOffer.id}
      guid={guid}
      isActivationAllowed={isAuthenticated}
      promotionType={tradeOffer.promotionType}
      onPartnerButtonClick={onPartnerButtonClick}
    >
      <TradeOfferSidebarDialogsProvider
        tradeOffer={tradeOffer}
        onShowAllOffers={onShowAllOffers}
      >
        <TradeOfferSlotSidebar />
      </TradeOfferSidebarDialogsProvider>
    </TradeOfferSidebarProvider>
  );
};

export default TradeOfferSidebarAdapter;
