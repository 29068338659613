import { createSelector } from '@reduxjs/toolkit';
import { userApi } from 'data/api/user';
import { RootState } from 'data/store/store';

export const currentUserIdSelector = (store: RootState): Nullable<UUID> => {
  return userApi.endpoints.getCurrentUser.select({})(store).data?.id ?? null;
};

export const currentUserLocalityIdSelector = (store: RootState): Nullable<UUID> => {
  return userApi.endpoints.getCurrentUser.select({})(store).data?.locality?.id ?? null;
};

export const currentUserBalanceSelector = createSelector(
  currentUserIdSelector,
  (store: RootState) => store,
  (userId, store) => {
    if (!userId) {
      return null;
    }

    const { data: value, isLoading } = userApi.endpoints.getUserBonusesBalance.select({ userId })(store);
    return {
      value,
      isFetching: isLoading,
    };
  }
);
