import { Link } from '@mui/material';
import AboutPrivacyPolicyLinkPublic from 'presentation/features/about/components/privacyPolicyLink/public';
import TermsOfUseLinkPublic from 'presentation/features/about/components/termsOfUseLink/public';
import AboutFeedbackContainer from 'presentation/features/about/feedBack/container';
import { useAuth } from 'presentation/features/auth/provider/useAuth';
import FooterCopyRight from 'presentation/features/footer/components/copyright';
import FooterGroup from 'presentation/features/footer/components/group';
import FooterLayout from 'presentation/features/footer/layout';
import useTechConfig from 'presentation/hooks/useTechConfig';
import { EAppFeature } from 'presentation/types';
import { getPartnerPersonalAccountUrl, getSupportEmail } from 'presentation/utils';
import { useState } from 'react';

const Layout = FooterLayout;

const MainFooterContainer = () => {
  const [feedbackVisible, setFeedbackVisible] = useState<boolean>(false);

  const { isAuthenticated } = useAuth();
  const { hasFeature } = useTechConfig();

  return (
    <Layout>
      <FooterGroup label='Покупателям'>
        <Link
          color='textPrimary'
          underline='none'
          href='/support/Как пользоваться Витриной.pdf'
          target='_blank'
          variant='body2'
        >
          Как пользоваться Витриной
        </Link>
        {hasFeature(EAppFeature.TermsOfUse) && (
          <TermsOfUseLinkPublic
            underline='none'
            variant='body2'
          >
            Пользовательское соглашение
          </TermsOfUseLinkPublic>
        )}
        {hasFeature(EAppFeature.PersonalDataPolicy) && (
          <AboutPrivacyPolicyLinkPublic
            underline='none'
            variant='body2'
          >
            Политика обработки персональных данных в&nbsp;ОАО&nbsp;«РЖД»
          </AboutPrivacyPolicyLinkPublic>
        )}
      </FooterGroup>

      {isAuthenticated && getPartnerPersonalAccountUrl() && (
        <FooterGroup label='Партнёрам'>
          <Link
            color='textPrimary'
            underline='none'
            href={getPartnerPersonalAccountUrl()}
            target='_blank'
            variant='body2'
          >
            Личный кабинет партнёра
          </Link>
        </FooterGroup>
      )}

      <FooterGroup
        label='Контакты'
        footer={<FooterCopyRight />}
      >
        <Link
          color='textPrimary'
          underline='none'
          href={`mailto:${getSupportEmail()}`}
          variant='body2'
        >
          {getSupportEmail()}
        </Link>
        <Link
          component='div'
          color='textPrimary'
          underline='none'
          onClick={() => setFeedbackVisible(true)}
          variant='body2'
        >
          Форма обратной связи
        </Link>
      </FooterGroup>

      <AboutFeedbackContainer
        open={feedbackVisible}
        onClose={() => setFeedbackVisible(false)}
      />
    </Layout>
  );
};

export default MainFooterContainer;
