import * as H from 'history';
import { Location } from 'history';
import TradeOfferScreen from 'presentation/screen/tradeOffer';
import TradeOffersScreen from 'presentation/screen/tradeOffers';
import TradeOffersSearchResultsScreen from '../../../screen/tradeOffers/search';
import { Route, Switch } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { EGlobalUrlParam } from '../../header/utils';
import { ETradeOfferDetailsTab } from './details/utils';
import { ETradeOfferUrlParam } from './utils';
import { CorpOffersListLocationState } from '../corp/entry';
import { Nullable } from '../../../../domain/model/types';
import { routing } from './routes';
import { AppRoute } from '../../general/routing';
import { EAppFeature } from '../../../types';
import AspOffersScreen from '../../../screen/aspOffers';
import { useAuthUser } from '../../auth/provider/useAuthUser';

type GetTradeOfferDetailsRouteProps = {
  readonly id: UUID;
  readonly guid?: Nullable<UUID>;
  readonly tab?: ETradeOfferDetailsTab;
};

export type TradeOffersListLocationState = {
  readonly guid: UUID;
};

export type TradeOfferDetailsLocationState = {
  readonly guid: UUID;
};

export const getTradeOfferDetailsRoute = ({
  id,
  tab,
  guid,
}: GetTradeOfferDetailsRouteProps): H.Location<TradeOfferDetailsLocationState> => {
  const params = new URLSearchParams();

  const newGuid = guid ?? uuidv4();
  if (tab) {
    params.append(ETradeOfferUrlParam.Tab, tab);
  }

  return {
    pathname: `${routing.details.replace(':id', id)}`,
    search: params.toString(),
    state: {
      guid: newGuid,
    },
    hash: '',
  };
};

type GetTradeOffersSearchRouteProps = {
  readonly name?: Nullable<string>;
  readonly categoryId?: Nullable<UUID>;
  readonly guid?: Nullable<UUID>;
  readonly partnerId?: Nullable<UUID>;
};

export const getTradeOffersSearchRoute = (
  props: GetTradeOffersSearchRouteProps
): H.Location<TradeOffersListLocationState> => {
  const { name, categoryId, guid, partnerId } = props;

  const newGuid = guid ?? uuidv4();

  const params = new URLSearchParams();

  if (name) {
    params.append(EGlobalUrlParam.Search, name);
  }

  if (categoryId) {
    params.append(ETradeOfferUrlParam.Category, categoryId);
  }

  if (partnerId) {
    params.append(ETradeOfferUrlParam.PartnerId, partnerId);
  }

  return {
    pathname: routing.list,
    search: params.toString(),
    state: {
      guid: newGuid,
    },
    hash: '',
  };
};

type GetTradeOffersListRouteProps = {
  readonly guid?: Nullable<UUID>;
};

export const getTradeOffersListRoute = (
  props?: GetTradeOffersListRouteProps
): Location<CorpOffersListLocationState> => {
  const newGuid = props?.guid ?? uuidv4();

  return {
    pathname: routing.list,
    search: '',
    state: {
      guid: newGuid,
    },
    hash: '',
  };
};

const TradeOfferEntry = () => {
  const { isCorpUser } = useAuthUser();
  return (
    <Switch>
      <Route
        exact
        path={routing.list}
        component={TradeOffersScreen}
      />
      <Route
        exact
        path={routing.search}
        component={TradeOffersSearchResultsScreen}
      />
      {isCorpUser && (
        <AppRoute
          protect
          exact
          feature={EAppFeature.Asp}
          path={routing.asp}
          component={AspOffersScreen}
        />
      )}
      <Route
        exact
        path={routing.details}
        component={TradeOfferScreen}
      />
    </Switch>
  );
};

export default TradeOfferEntry;
