import RequiredGuidInLocationContainer from 'presentation/features/general/locationGuid/container';
import { EGlobalUrlParam } from 'presentation/features/header/utils';
import { TradeOffersListLocationState } from 'presentation/features/offer/trade/entry';
import TradeOfferListContainer from 'presentation/features/offer/trade/list/container';
import { ETradeOfferUrlParam } from 'presentation/features/offer/trade/utils';
import ScreenLayout from 'presentation/layouts/screen';
import { useLocation } from 'react-router';
import { ETradeOfferPromotionType } from '../../../domain/model/enums';

const TradeOffersScreen = () => {
  const location = useLocation<Nullable<TradeOffersListLocationState>>();

  const searchParams = new URLSearchParams(location.search);
  const name = searchParams.get(EGlobalUrlParam.Search);
  const categoryId = searchParams.get(ETradeOfferUrlParam.Category) ?? null;
  const partnerId = searchParams.get(ETradeOfferUrlParam.PartnerId) ?? null;

  return (
    <RequiredGuidInLocationContainer>
      {guid => (
        <ScreenLayout footer>
          <TradeOfferListContainer
            name={name}
            categoryId={categoryId}
            partnerId={partnerId}
            guid={guid}
            promotionType={[
              ETradeOfferPromotionType.Widget,
              ETradeOfferPromotionType.ReferralLink,
              ETradeOfferPromotionType.PublicPromocode,
              ETradeOfferPromotionType.Voucher,
              ETradeOfferPromotionType.Promocode,
              ETradeOfferPromotionType.AccessCode,
            ]}
          />
        </ScreenLayout>
      )}
    </RequiredGuidInLocationContainer>
  );
};

export default TradeOffersScreen;
