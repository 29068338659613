import { useGetTradeOfferDetailsQuery } from 'data/api/tradeOffer';
import { EOfferType, OfferPromotionType } from 'domain/model/enums';
import moment from 'moment-timezone';
import { getUserProfileRoute } from 'presentation/features/user/entry';
import { normalizedLink } from 'presentation/utils';
import React, { FCC, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import useOfferActivations from '../../../../hooks/useOfferActivations';
import useOfferSubscription from '../../../../hooks/useOfferSubscription';
import { isOfferReceiptDateSupportedByPromotionType } from '../../../../utils';
import { getTradeOfferCodeAssignDurationInSeconds } from '../../../utils';
import TradeOfferSidebarContext, { TradeOfferSidebarContextType } from './context';
import useTakeOfferActivations from '../../../../hooks/useTakeOfferActivations';
import { useAuth } from '../../../../../auth/provider/useAuth';

type TradeOfferSidebarProviderProps = {
  readonly id: UUID;
  readonly guid: UUID;
  readonly promotionType: OfferPromotionType;
  readonly isActivationAllowed?: boolean;
  readonly onPartnerButtonClick: () => void;
};

const TradeOfferSidebarProvider: FCC<TradeOfferSidebarProviderProps> = props => {
  const { children, id, promotionType, onPartnerButtonClick, guid, isActivationAllowed = true } = props;

  const history = useHistory();

  const [timerActive, setTimerActive] = React.useState<boolean>(false);

  const { isAuthenticated, login } = useAuth();

  const { data: tradeOffer = null, refetch: tradeOfferRefetch } = useGetTradeOfferDetailsQuery({ id });

  const activation = useOfferActivations({
    offerId: id,
    offerRefetch: tradeOfferRefetch,
    isActivationAllowed,
  });

  const { lastActivation, activationError, isLoadActivationsFetching, lastReceivedActivation } = activation;

  const { analyzeOfferActivation, onTakeActivation, needCopy, isTaked, onNeedCopy, isTaking, onCopy } =
    useTakeOfferActivations({
      lastActivation,
    });

  const {
    isEnabled: isSubscriptionEnabled,
    isFetching: isSubscriptionFetching,
    isSupported: isSubscriptionSupported,
    onSubscribe,
    onUnSubscribe,
  } = useOfferSubscription({
    offer: tradeOffer,
    offerType: EOfferType.Trade,
    promotionType,
  });

  const isReceiptDateSupported = isOfferReceiptDateSupportedByPromotionType(promotionType);

  const isActivationAvailable = activation.isActivationAvailable;
  const partnerLink = tradeOffer?.partnerLink ? normalizedLink(tradeOffer.partnerLink) : '';
  const endDate = tradeOffer ? moment(tradeOffer.endDate).endOf('day') : null;

  const isFetching = isLoadActivationsFetching;
  const isActivationReceivedNow = !!lastReceivedActivation;

  const onActivate = useCallback(async () => {
    if (!isAuthenticated) {
      login();
      return null;
    }

    if (!tradeOffer) {
      return null;
    }
    analyzeOfferActivation(tradeOffer);
    const result = await activation.activateOffer(tradeOffer);
    if (result) {
      onTakeActivation(result);
    }

    return result;
  }, [isAuthenticated, tradeOffer, analyzeOfferActivation, activation, login, onTakeActivation]);

  const stopTimer = useCallback(() => {
    setTimerActive(false);
  }, [setTimerActive]);

  const onFillProfile = useCallback(() => {
    history.push(getUserProfileRoute());
  }, [history]);

  useEffect(() => {
    const duration = lastActivation?.appointmentDate
      ? getTradeOfferCodeAssignDurationInSeconds(lastActivation?.appointmentDate)
      : 0;
    setTimerActive(duration > 0);
  }, [lastActivation?.appointmentDate, activationError]);

  const value: TradeOfferSidebarContextType = {
    tradeOffer,
    partnerLink,
    endDate,
    activation,
    isFetching,
    guid,
    isSubscriptionEnabled,
    isSubscriptionFetching,
    isSubscriptionSupported,
    isReceiptDateSupported,
    isActivationAvailable,
    isActivationReceivedNow,
    isTimerActive: timerActive,
    stopTimer,
    onTakeActivation,
    onActivate,
    onSubscribe,
    onUnSubscribe,
    onPartnerButtonClick,
    onFillProfile,
    onCopy,
    isTaked,
    needCopy,
    onNeedCopy,
    isTaking,
  };

  return <TradeOfferSidebarContext.Provider value={value}>{children}</TradeOfferSidebarContext.Provider>;
};

export default TradeOfferSidebarProvider;
